<template>
  <div>
    <sidenav :is-sidebar-active.sync="isSidebarActive" :item-edit.sync="itemEdit"
             :is-add.sync="isAdd"
             @refetch-data="refetchData" :tenant-options="tenantOptions"
             :router-options="routerOptions"/>
    <!-- Filters -->
    <filters :tenant-filter.sync="tenantFilter" :tenant-options="tenantOptions"/>

    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6"
                 class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false" class="per-page-selector d-inline-block mx-50"/>
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                            :placeholder="$t('table.search')"/>

            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refListTable" class="position-relative" :items="fetchList" responsive
               :fields="tableColumns"
               primary-key="id" :sort-by.sync="sortBy" show-empty
               :empty-text="$t('no_record_found')"
               :sort-desc.sync="isSortDirDesc">
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>

        <template #head(name)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(description)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(tenant)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(created_at)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(updated_at)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(active)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #cell(url)="data">
          <div class="hoverable">
            <b-img :src="data.item.url" alt="Sin Imagen" style="max-height: 150px"/>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16"
                            class="align-middle text-body"/>
            </template>

            <b-dropdown-item @click="openEdit(data.item)"
                             v-if="$can('update_users_app', 'UsersApp')">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50 text-capitalize">{{
                  $t("actions.edit")
                }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmDelete(data.item.ID)"
                             v-if="$can('delete_users_app', 'UsersApp')">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50 text-capitalize">{{
                  $t("actions.delete")
                }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="passwordReset(data.item.ID)"
                             v-if="$can('update_users_app', 'UsersApp')">
              <feather-icon icon="LockIcon"/>

              <span class="align-middle ml-50 text-capitalize">{{
                  $t("users_app.reset_password")
                }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6"
                 class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted text-capitalize">{{
                $t("table.showing", {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6"
                 class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <div></div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {ref} from "@vue/composition-api";
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
import Filters from "./Filters.vue";
import {useToast} from "vue-toastification/composition";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import Pagination from "@/components/Pagination.vue";

export default {
  components: {
    Sidenav,
    Filters,
    Pagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    vSelect,
  },

  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination


    const isSidebarActive = ref(false);
    const itemEdit = ref({});
    const isAdd = ref(false);

    const openEdit = (item) => {
      isSidebarActive.value = true;
      itemEdit.value = item;
      isAdd.value = false;
    };
    const {t} = useI18nUtils();

    const toast = useToast();

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      tenantOptions,
      tenantFilter,
      routerOptions
    } = useList();

    const confirmDelete = function (id) {
      const self = this;
      const storeInner = store;
      this.$swal({
        title: context.parent.$i18n.t("messages.delete.title"),
        text: context.parent.$i18n.t("messages.delete.body"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: context.parent.$i18n.t("actions.delete"),
        cancelButtonText: context.parent.$i18n.t("actions.cancel"),
        customClass: {
          confirmButton: "btn btn-primary text-capitalize",
          cancelButton: "btn btn-outline-danger ml-1 text-capitalize",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          storeInner
            .dispatch("user_app/delete", id)
            .then((response) => {
              if (response.success) {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                self.refetchData();
              } else {
                toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 10000,
                  }
                );
              }
            })
            .catch((response) => {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: response.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                },
                {
                  timeout: 10000,
                }
              );
            });
        }
      });
    };

    const passwordReset = (id) => {
      toast({
        component: ToastificationContent,
        props: {
          title: "Enviando correo",
          icon: "CheckIcon",
          variant: "success",
        },
      });

      store.dispatch("user_app/getEmail", id)
        .then((response) => {

          toast({
            component: ToastificationContent,
            props: {
              title: "Correo enviado para el restablecimiento de la contraseña",
              icon: "CheckIcon",
              variant: "success",
            },
          });


        })

        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Correo no enviado para el restablecimiento de la contraseña",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const openAdd = () => {
      isAdd.value = true;
      isSidebarActive.value = true;
    };

    return {
      isSidebarActive,
      isAdd,
      itemEdit,
      openEdit,
      openAdd,
      confirmDelete,
      update,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      passwordReset,
      tenantOptions,
      tenantFilter,
      routerOptions
    };
  },
};
</script>